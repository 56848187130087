import React, { useState } from 'react';

import { Sidebar, Menu, MenuItem, SubMenu, ProSidebarProvider } from 'react-pro-sidebar';

import './sidebar.scss';
import { i18ndb, rest, t } from '../../../auto/js/services';
import { menu } from '../../../main/js/router/Menu';
import { Button } from '@material-ui/core';
import { SidebarHeader } from '../../../main/js/widgets/SidebarHeader';
import { SidebarFooter } from '../../../main/js/widgets/SidebarFooter';
import { UserAvatar } from '../users/Avatars';
import { whoami } from '../users/UserInfo';


const menuItemStyles = {
  SubMenuExpandIcon: {
    color: '#b6b7b9',
  },
  subMenuContent: ({ level }) => ({
    backgroundColor: '#8ec8f7'
  }),
  button: {
    '&.active': {
      backgroundColor: '##80bcec',
      borderRadius: '30px'
    },
    '&:hover': {
      backgroundColor: '##80bcec',
      color: '#fff',
      borderRadius: '30px'
    },
  }
};


const buildHeaderContent = () => {
  return (
    <div
      style={{
        padding: '9px 9px 15px 9px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 15,
        letterSpacing: '1px',
        display: 'flex',
        lineHeight: '40px'
      }}
    >
      {UserAvatar(whoami())}
      {t`Perfil gestor`}
    </div>
  )
}

const buildFooterContent = () => {
  return (
    <>
    <div>  
    <Button
        color="inherit"
        style={{paddingLeft: '20px', textTransform: 'none'}}
        onClick={null}>
        {t`Options`}
    </Button>
    </div>
    <Button
        color="inherit"
        style={{paddingLeft: '20px', textTransform: 'none'}}
        onClick={() => {rest.oidcService.logout();}}>
        {t`Sign out`}
    </Button>
    </>
  )
}

export const SideMenu = () => {
  const [activeIndex, setActiveIndex] = useState({parentIndex: null, index: 0});

  const onItemClick = (parentIndex, index, descriptorDo) => {
    descriptorDo();
    setActiveIndex({parentIndex: parentIndex, index: index});
  }

  const buildItemList = (subMenu, parentIndex) => {
    let items = [];
    const subMenuKeys = Object.keys(subMenu);
    subMenuKeys.forEach((key) => {
        let options = subMenu[key].options;
        let optionsKeys = Object.keys(options);
        optionsKeys.forEach((key, index) => {
          items.push(
          <MenuItem active={(parentIndex === activeIndex.parentIndex && index === activeIndex.index)} onClick={() => onItemClick(parentIndex, index, options[key].do)}> {i18ndb._(options[key].label)} </MenuItem>
          )
        });
    });
    return items;
  }
  
  const buildMenu = (options) => {
    const items = []
    const optionsKeys = Object.keys(options);
    optionsKeys.forEach((key, index) => {
      if (options[key]["submenu"])
        items.push(
          <SubMenu label={i18ndb._(options[key].label)} icon={(options[key].icon)?options[key].icon:null}>
            {buildItemList(options[key]["submenu"], index)}
          </SubMenu>
        )
      else
        items.push(
          <MenuItem active={(activeIndex.parentIndex === null && index === activeIndex.index)} onClick={() => onItemClick(null, index, options[key].do)} icon={(options[key].icon)?options[key].icon:null}> {i18ndb._(options[key].label)} </MenuItem>
        )
    });
    return items;
  }

    return (
      <div style={{ display: 'flex', height: '100%' }}>
      <ProSidebarProvider>
        <Sidebar
          backgroundColor={'#8ec8f7'}
          rootStyles={{
            color: '#ffffff',
          }}
        >
        {/* Header */}
        <SidebarHeader children={buildHeaderContent()} />
        {/* Content */}
          <Menu menuItemStyles={menuItemStyles}>
            {buildMenu(menu())}
          </Menu>
        {/* Footer */}
        <SidebarFooter  children={buildFooterContent()} />
      </Sidebar>
    </ProSidebarProvider>
    </div>
    )
}
