import React from "react";
import { v4 as uuidv4 } from 'uuid';

import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';
import { rest } from "../../../auto/js/services";
import { showNotification } from "../../../auto/js/utils";
import { affiliationRecordFormfields } from "../../../auto/js/forms/AffiliationRecordFormCommon"

export const displayReadAffiliationRecordForm = (id) => {
	let AffiliationRecordForm = createFormComponent(affiliationRecordFormfields);
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid,
		view: () => {
			return (
				<>
					<AffiliationRecordForm key={uuid} loadData={async () => loadFormData(id)} readOnly buttons={() => null}/>
				</>
			)
		}
	});
}

const loadFormData = async (id) => {
	return await rest.read('affiliation-record', id).then(async response => {
		let form = response;
        let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)			
		}
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return form;
	})
}