import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import { displayAffiliationApplicationList } from '../../../auto/js/lists/affiliationApplication/AffiliationApplicationList';
import { SIDE_MENU } from '../../../auto/js/metadata/MenuType';
import {whoami} from '../../../auto/js/users/UserInfo';
import { displayAffiliationRecordList } from '../../../auto/js/lists/AffiliationRecordList';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "affiliation": {
                submenu: {
                    "affiliation": {
                        options: {
                            list: {label: "Solicitudes de afiliación", do: displayAffiliationApplicationList},
                            recordList: {label: "Afiliación", do: displayAffiliationRecordList},
                        },
                        label: "Afiliación"
                    }
                },
                label: "Afiliación"
            }
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "configuration": {
                submenu: {
                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New Translation", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration"
            }
        })
    }
}
export const menuType = SIDE_MENU;