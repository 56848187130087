import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services";
import { AFFILIATION_MTLB_ORDER_BY_NAME } from "../../../../auto/js/metadata/AffiliationMtlbOrderBy";

export const affiliationApplicationListColumns = [
    {title: "id", field: "id"},
    {title: "name", field: "name"},
    {title: "submissionDate", field: "submissionDate"},
    {title: "mtlbStatus", field: "mtlbStatus"},
    {title: "issuedBy", field: "issuedBy"},
];

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "affiliation-mtlb/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "affiliation-mtlb/count-document/", "POST", filter);
}

export const getOrderBy = () => {
    return AFFILIATION_MTLB_ORDER_BY_NAME
}

export const getMtlbTypeList = () => {
    return []
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.submissionDate
        if ( date !== null)
        row.submissionDate = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    return list;
}
