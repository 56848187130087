import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { UserInfo } from '../users/UserInfo';
import { isBlank } from '../utils';

function stringToColor(s: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < s.length; i += 1) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  if (!isBlank(name))
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export function UserAvatar(user: UserInfo)
{
	let lastname = user.lastname || '';
	let picture = user.picture || '';
	let name = '';
	if (!isBlank(user.firstname))
		name = user.firstname + ' ' + lastname;
	else if (!isBlank(user.lastname))
		name = lastname;
  	else {
  		if (isBlank(picture))
    		picture = "/public/avatar.png";
	}
	return (
		<Avatar alt={name} src={picture} sx={{ width: 40, height: 40, marginRight: '25px' }}>
			<Avatar {...stringAvatar(name)} sx={{ width: 40, height: 40, 'margin-left': '4px', 'font-size': '1rem' }}/>;
		</Avatar>
	)
}