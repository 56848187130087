import React from "react";
import { AFFILIATION_RECORD_ORDER_BY_NAME } from "../metadata/AffiliationRecordOrderBy";

export const affiliationRecordColumns = [
    {title: "id", field: "id"},
    {title: "name", field: "name"},
    {title: "affiliationDate", field: "affiliationDate"},
    {title: "issuedBy", field: "issuedBy"},
    {title: "ppt", field: "recordId"}
]

export const getOrderBy = () => {
    return AFFILIATION_RECORD_ORDER_BY_NAME
}

export const filterData = async (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.affiliationDate
        if ( date !== null)
        row.affiliationDate = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}
