import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";

import { affiliationRecordColumns, getOrderBy, filterData } from "../../../auto/js/lists/AffiliationRecordListCommon";
import { displayReadAffiliationRecordForm } from "../forms/AffiliationRecordForm";

const AffiliationRecordListPage = ({uuid}) => {

    let AffiliationRecordList = createTableComponent(affiliationRecordColumns);

    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["orderBy"] = getOrderBy();
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        filter["affiliation-record"] = {}
       
        if (query.search && query.search!='') {
            pojoMetadata["affiliation-record"].columns.forEach(element => {
                if(element.type=='text'){
                    filter["affiliation-record"][element.key]= query.search;
                }
            });
            filter["and"] = false;
            filter.fuzziness = "AUTO";
        }
        return await getRecordData(filter).then(response => {
            return filterData(response).then((data) => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }

    const getRecordData = async (filter) => {
        return await rest.search("affiliation-record", filter);
    }
    
    const countData = async (filter) => {
        return await rest.count("affiliation-record", filter);
    }



    return (
        <div>
            <AffiliationRecordList key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions()} />
        </div>
    )
}

export const displayAffiliationRecordList = () => {
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () =>  <AffiliationRecordListPage uuid={uuid} />
	});
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`View`,
                onClick: (event, rowData) => {
                    displayReadAffiliationRecordForm(rowData.id);
                }
        }
    )
    return actions;
}